<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        权限说明
      </h1>
      <h1 :dir="dir">
        为了保证您正常的游戏体验，将向您申请获取以下权限：
      </h1>
      <div class="text notitle">
        储存权限：下载和安装游戏更新内容, 拒绝授权将可能无法正常进行游戏<br><br>
        电话权限：需要申请电话权限以获取设备标识(AndroidID、mac)，生成帐号、保存和恢复游戏数据<br><br>
        后台运行权限：允许程序在手机屏幕关闭后后台进程仍然运行，防止游戏进程断开，返回游戏时无需重启游戏<br><br>
        网络状态权限：允许获取当前WiFi接入的状态以及WLAN热点的信息，确保游戏在联网情况下才可正常运行<br><br>
        获取应用安装列表权限：收集应用程序性能、崩溃等情况，为了预防恶意程序以及安全运营所必需
      </div>
      <h1 :dir="dir">
        以下是抖音渠道集成所需权限
      </h1>
      <div class="text scrollable">
        <table>
          <thead>
            <tr>
              <th>
                调用的设备权限
              </th>
              <th>
                调用的设备权限
              </th>
              <th>
                调用权限目的
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                CAMERA 拍摄
              </td>
              <td>
                使用拍摄照片和视频、完成扫描二维码
              </td>
              <td>
                用于社区功能，发布内容时索取相机权限
              </td>
            </tr>
            <tr>
              <td>
                ACCESS_FINE_LOCATION 访问精准定位
              </td>
              <td>通过全球定位系统（GPS）或网络位置信息（例如基站和WLAN）获取精准地理位置信息</td>
              <td>
                用于完成安全保障、推荐信息以及基于地理位置的服务（LBS）等相关功能
              </td>
            </tr>
            <tr>
              <td>
                ACCESS_COARSE_LOCATION
                访问粗略位置
              </td>
              <td>通过网络位置信息（例如基站和WLAN）获取大致地理位置信息</td>
              <td>用于完成安全保障服务及基于地理位置的服务（LBS）等相关功能</td>
            </tr>
            <tr>
              <td>
                READ_PHONE_STATE
                读取电话状态(设备 IMSI/IMEI 号）
              </td>
              <td>提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息</td>
              <td>
                读取设备通话状态和识别码，识别手机设备ID，保证运营商网络免流服务，用于完成音视频、信息展示、账号登录、安全保障等主要功能
              </td>
            </tr>
            <tr>
              <td>
                READ_EXTERNAL_STORAGE
                读取外置存储器
              </td>
              <td>
                提供读取手机储存空间内数据的功能
              </td>
              <td>
                允许App读取存储中的图片、文件等内容，主要用于帮助您发布信息，在本地记录崩溃日志信息（如有）等功能
              </td>
            </tr>
            <tr>
              <td>
                WRITE_EXTERNAL_STORAGE
                写入外置存储器
              </td>
              <td>
                提供写入外部储存功能
              </td>
              <td>
                允许App写入/下载/保存/修改/删除图片、文件、崩溃日志等信息
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
